import type { LocationResponse } from './location-service'
import type { RawProduct } from './raw-product-service'
import type { Page } from '~~/types/api-common'
import type { MeasureType, PriceType } from '~~/types/buy-event'

export const useProcurementQuotationService = () => {
    const fetchProcurementQuotations = async (page = 1, size = 100, projection = [], idLocation, since, until) => {
        const params: Record<string, any> = {
            page,
            size,
            projection: [
                ...projection,
            ],
            since,
            until,
            id_location: idLocation,
        }
        const data = await api<Page<ProcurementQuotationResponse>>(
            '/procurement_quotation/',
            { params },
        )

        return data
    }

    const createProcurementQuotation = async (procurementQuotation: ProcurementQuotationCreate) => {
        const data = await api.post<ProcurementQuotationResponse>(
            '/procurement_quotation/',
            procurementQuotation,
        )
        return data
    }

    const patchProcurementQuotation = async (procurementQuotation: ProcurementQuotationUpdate) => {
        const data = await api.patch<ProcurementQuotationResponse>(
            `/procurement_quotation/${procurementQuotation.id_procurement_quotation}`,
            procurementQuotation,
        )
        return data
    }

    const deleteProcurementQuotation = async (id: string) => {
        const data = await api.delete<void>(
            `/procurement_quotation/${id}`,
        )
        return data
    }

    return { fetchProcurementQuotations, createProcurementQuotation, patchProcurementQuotation, deleteProcurementQuotation }
}

export interface ProcurementQuotationCreate {
    id_raw_product: string
    id_maturation_type?: string
    id_quality_type?: string
    id_location?: string
    id_provider: string
    price: number
    minimum_units?: number
    minimum_weight?: number
    minimum_boxes?: number
    wholesale_quantity?: number
    wholesale_recipient_price?: number
    valid_from: string
    valid_until: string
    created_by: string
}

export interface ProcurementQuotationUpdate {
    id_procurement_quotation: string
    id_raw_product: string
    id_maturation_type?: string
    id_quality_type?: string
    id_location?: string
    id_provider: string
    price: number
    minimum_units?: number
    minimum_weight?: number
    minimum_boxes?: number
    wholesale_quantity?: number
    wholesale_recipient_price?: number
    valid_from: string
    valid_until: string
    created_by: string
}

export interface ProcurementQuotationResponse {
    id_procurement_quotation: string
    id_raw_product: string
    id_maturation_type?: string
    id_quality_type?: string
    id_location?: string
    id_provider: string
    price: number
    minimum_units?: number
    minimum_weight?: number
    minimum_boxes?: number
    wholesale_quantity?: number
    wholesale_recipient_price?: number
    valid_from: string
    valid_until: string
    created_by: string
    created_at: string
    updated_at: string
    deleted_at?: any
    quality_type?: QualityTypeResponse
    maturation_type?: MaturationTypeResponse
    raw_product?: RawProduct
    location?: LocationResponse
    provider?: ProviderResponse
}

export interface ProcurementQuotation {
    id?: string
    rawProductId: string
    providerId: string
    locationId: string
    price: number
    maturationTypeId?: string
    qualityTypeId?: string
    minimumUnits?: number
    minimumWeight?: number
    minimumBoxes?: number
    wholesaleQuantity?: number
    wholesaleRecipientPrice?: number
    validFrom: string
    validUntil: string
    createdBy: string
    rawProduct?: RawProduct
    provider?: ProviderResponse
    measureType?: MeasureType
    quantity?: number
    priceType?: PriceType
}

interface MaturationTypeResponse {
    id_maturation_type: string
    name: string
}

interface QualityTypeResponse {
    id_quality_type: string
    name: string
}

interface ProviderResponse {
    id_provider: string
    name: string
}
