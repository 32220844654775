import { acceptHMRUpdate, defineStore } from 'pinia'
import type { ProcurementQuotation, ProcurementQuotationCreate, ProcurementQuotationResponse, ProcurementQuotationUpdate } from './services/procurement-quotation-service'
import { useProcurementQuotationService } from './services/procurement-quotation-service'
import { MeasureType, PriceType } from '~~/types/buy-event'
import mod from 'zod/lib'

const getMeasureTypeFromResponse = (response: ProcurementQuotationResponse): MeasureType => {
    if (response?.minimum_units > 0) {
        return MeasureType.Unit
    }
    else if (response?.minimum_boxes > 0) {
        return MeasureType.Box
    }
    return MeasureType.Weight
}

const mapResponseToBase = (item: ProcurementQuotationResponse): ProcurementQuotation => ({
    id: item.id_procurement_quotation,
    rawProductId: item.id_raw_product,
    providerId: item.id_provider,
    locationId: item.id_location,
    price: item.price,
    minimumUnits: item.minimum_units,
    minimumWeight: item.minimum_weight,
    minimumBoxes: item.minimum_boxes,
    wholesaleQuantity: item.wholesale_quantity,
    wholesaleRecipientPrice: item.wholesale_recipient_price,
    validFrom: item.valid_from,
    validUntil: item.valid_until,
    createdBy: item.created_by,
    provider: item.provider,
    rawProduct: item.raw_product,
    measureType: getMeasureTypeFromResponse(item),
    quantity: item.minimum_units + item.minimum_weight + item.minimum_boxes,
})

const dateFromString = (date: string) => {
    const year = +date.substring(0, 4)
    const month = +date.substring(5, 7)
    const day = +date.substring(8, 10)

    return new Date(year, month - 1, day)
}

const mapBaseToCreateOrUpdate = (model: ProcurementQuotation): ProcurementQuotationCreate | ProcurementQuotationUpdate => {
    const priceType = model.priceType
    const minimum_units = model.measureType === MeasureType.Unit ? model.quantity : null
    const minimum_weight = model.measureType === MeasureType.Weight ? model.quantity : null
    const minimum_boxes = model.measureType === MeasureType.Box ? model.quantity : null

    let price = model.price
    let total_price = null
    const quantity = minimum_boxes || minimum_units || minimum_weight || 1

    if (priceType === PriceType.TotalPrice) {
        total_price = price
        price = parseFloat((total_price / quantity).toFixed(2))
    } else {
        price = price
        total_price = parseFloat((price * quantity).toFixed(2))
    }

    return {
        id_procurement_quotation: model.id || undefined,
        id_maturation_type: model.maturationTypeId || undefined,
        id_quality_type: model.qualityTypeId || undefined,
        id_raw_product: model.rawProductId,
        id_location: model.locationId,
        id_provider: model.providerId,
        price: price,
        total_price: total_price,
        minimum_units: minimum_units,
        minimum_weight: minimum_weight,
        minimum_boxes: minimum_boxes,
        wholesale_quantity: model.measureType === MeasureType.Box ? model.wholesaleQuantity : null,
        valid_from: dateFromString(model.validFrom).toISOString(),
        valid_until: dateFromString(model.validUntil).toISOString(),
        created_by: model.createdBy,
    }
}

export const useProcurementQuotationStore = defineStore('procurement-quotation', {
    state: () => ({
        procurementQuotationList: [],
        procurementQuotationListPageTotal: 0,
    }) as ProcurementQuotationStoreState,

    actions: {
        async listProcurementQuotations({ size = 1000, page = 1, projection = [], idLocation, since, until }: ListProcurementQuotationsParams) {
            const { fetchProcurementQuotations } = useProcurementQuotationService()
            log('Listing procurement quotations')
            const data = await fetchProcurementQuotations(page, size, projection, idLocation, since, until)
            this.procurementQuotationList = data.items?.map(mapResponseToBase) || []
            this.procurementQuotationListPageTotal = Math.ceil(data.total / size) || 0
        },

        async createProcurementQuotation(procurementQuotation: ProcurementQuotation) {
            log('Creating procurement quotation')
            const { createProcurementQuotation } = useProcurementQuotationService()
            const create = mapBaseToCreateOrUpdate(procurementQuotation)
            const createdProcurementQuotation = await createProcurementQuotation(create)

            this.procurementQuotationList = [...this.procurementQuotationList, mapResponseToBase(createdProcurementQuotation)]

            return createdProcurementQuotation
        },

        async patchProcurementQuotation(procurementQuotation: ProcurementQuotation) {
            log('Updating procurement quotation')
            const { patchProcurementQuotation } = useProcurementQuotationService()
            const patch = mapBaseToCreateOrUpdate(procurementQuotation)
            const patchedProcurementQuotation = await patchProcurementQuotation(patch as any)

            const index = this.procurementQuotationList.findIndex(_item => _item.id === patchedProcurementQuotation.id_procurement_quotation)
            if (index > -1) {
                this.procurementQuotationList[index] = mapResponseToBase(patchedProcurementQuotation)
            }

            return patchProcurementQuotation
        },

        async deleteProcurementQuotation(id: string) {
            log('Deleting procurement quotation')
            const { deleteProcurementQuotation } = useProcurementQuotationService()
            await deleteProcurementQuotation(id)
            this.procurementQuotationList = this.procurementQuotationList.filter(quotation => quotation.id !== id)
        },
    },

    getters: {},
})

interface ListProcurementQuotationsParams {
    since?: string
    until?: string
    valid_from?: string
    valid_until?: string
    rawProductId?: string
    idLocation?: string
    qualityTypeId?: string
    maturationTypeId?: string
    providerId?: string
    size?: number
    page?: number
    projection?: string[]
}

interface ProcurementQuotationStoreState {
    procurementQuotationList: ProcurementQuotation[]
    procurementQuotationListPageTotal: number
}

function log(...args: any[]) {
    if (__DEV__) { console.info('[Procurement Quotation Store]', ...args) }
}

if (import.meta.hot) { import.meta.hot.accept(acceptHMRUpdate(useProcurementQuotationStore, import.meta.hot)) }
